import * as React from "react"
import { Helmet } from "react-helmet"

import HPPLayout from "../../components/layouts/layout-hpp"
import Seo from "../../components/seo"

import EopCalloutCards from "../../components/shared/generic/eop-callout-cards"

import CalloutIcon1 from "../../images/icons/icon-callout-hp-efficacy.png"
import CalloutIcon2 from "../../images/icons/icon-callout-hp-dosing.png"
import akbcares from "../../images/AkebiaCares-logo.png"

const HpConnectThankYouPage = ({ location }) => {

    let requestRep;
    let requestSamples;
    let state;
    if (typeof window !== "undefined") {
        requestRep = location.state.requestRep;
        requestSamples = location.state.requestSamples;
    }
    return (
        <>
            <HPPLayout
                location={location}
                locationHostName={location.hostname}
                jobCode="PP-AUR-US-1407 (v7.0)"
                jobCodeDate="07/23"
                references={[
                    // <>AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia Therapeutics, Inc.</>,
                    <>Data on File 1, Akebia Therapeutics, Inc.</>,
                    // <>National Kidney Foundation, K/DOQI clinical practice guidelines for bone metabolism and disease in chronic kidney disease. <em>Am J Kidney Dis</em>. 2003;42(4 Suppl 3):S1-S201.</>,
                    // <>Data on File 31, Akebia Therapeutics, Inc.</>

                ]}
            >
                <Helmet>
                    <html lang="en" />
                    <link rel="canonical" href="https://www.auryxiahcp.com/hyperphosphatemia/thank-you" />
                    <body id="hpp-hcp-connect-thank-you" className="hpp" />
                </Helmet>

                <Seo
                    title="Latest News Sign-up"
                    description="Talk to a sales representative, order samples, or receive additional information about AURYXIA. Read Important Safety Information and Prescribing Information on this page."
                />

                <section id="hero" className="flood-hp col-8 center">
                    <div className="flex-row">
                        <div>
                            <h1>THANK YOU</h1>
                            {requestRep ? (
                                <p id="contact-rep" className="center_text">You have successfully signed up to connect <br className="desktop"/>with a sales representative.</p>
                            ) : (
                                <p></p>
                            )}
                            {requestSamples ? (
                                <p id="request-samples" className="center_text">You have successfully signed up to <br className="desktop"/>request samples.</p>
                            ) : (
                                <p></p>
                            )}
                        </div>
                    </div>
                </section>

                <EopCalloutCards
                    siteAudience="hpp"

                    callOneClass={"highlight"}
                    callOneLink={"/hyperphosphatemia/ferric-citrate-efficacy/"}
                    callOneContent={
                        <>
                            <img src={CalloutIcon1} alt="" width="98" height="98" />
                            <h3>See how AURYXIA helped patients reach their target goals</h3>
                            <p>AURYXIA helped patients reach and stay in the KDOQI range of 3.5-5.5 mg/dL during a 56-week&nbsp;trial.<sup>1</sup><br /><br />
                                Patients had a mean serum phosphorus level of 7.41 mg/dL at baseline and 4.88 mg/dL at Week 56.<sup>1</sup></p>
                            <button className="cta hpp"><span>EXAMINE EFFICACY</span></button>
                        </>
                    }
                    callTwoClass={"default"}
                    callTwoLink={"/hyperphosphatemia/dosing/"}
                    callTwoContent={
                        <>
                            <img src={CalloutIcon2} alt="" width="98" height="98" />
                            <h3>Ready to prescribe?</h3>
                            <p>The first step to achieve the proven efficacy of AURYXIA is to start with the recommended dose. Find out how to dose and titrate&nbsp;AURYXIA.</p>
                            <button className="cta hpp"><span>GET DOSING INFORMATION</span></button>
                        </>
                    }
                />

                <section id="crosslink-slab" className="akb akb_moa_section">
                    <div
                        className="flex-row"
                        data-sal="zoom-in"
                        data-sal-duration="600"
                        data-sal-delay="200"
                        data-sal-ease="ease-out-expo"
                    >
                        <div>
                            <img src={akbcares} alt="AkebiaCares" height="81" />
                            <h3>Your partner in helping patients access the medication they need</h3>
                            <p className="boldTxt">Connect with a personal Case Manager today!</p>
                            <p className="med_width">AkebiaCares personal Case Managers are standing by live to answer your call and help your patients find coverage.
                          </p>
                            <ul className="info_ul">
                                <li><a href="tel:18334253242">1&#xFEFF;-&#xFEFF;833&#xFEFF;-&#xFEFF;4AKEBIA&nbsp;(425&#xFEFF;-&#xFEFF;3242)</a></li>
                                <li>Monday&nbsp;-&nbsp;Friday</li>
                                <li className="hours_li">8<span>AM</span>&nbsp;‐&nbsp;8<span>PM</span>&nbsp;EST</li>
                            </ul>

                            <a href="https://www.akebiacareshcp.com/" className="cta akb"><span>FIND SUPPORT OPTIONS</span></a>

                        </div>
                    </div>
                </section>

            </HPPLayout>
        </>
    )
}

export default HpConnectThankYouPage
